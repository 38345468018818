import React from "react";
import {
  Create,
  NumberInput,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
  required,
  FormDataConsumer,
  BooleanInput,
} from "react-admin";
import RichTextInput from "../../components/richInputText";
import CustomReferenceInput from "../../components/createButton";
import CustomReferenceArrayInput from "../tag/createButton";
import SubCatReferenceInput from "../subCategory/createButton";

const postDefaultValue = { tags: [] };
export default (props) => (
  <Create {...props} undoable="false">
    <TabbedForm initialValues={postDefaultValue}>
      <FormTab label="Details">
        <CustomReferenceInput
          source="category_id"
          reference="categories"
          perPage={9999}
          validate={required()}
          field="name"
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.category_id && (
              <SubCatReferenceInput
                source="sub_category_id"
                reference="sub_categories"
                resource="sub_categories"
                perPage={9999}
                filter={{ categories_id: formData.category_id }}
                validate={required()}
                field="name"
              />
            )
          }
        </FormDataConsumer>
        <TextInput source="title" validate={required()} />
        <TextInput source="supc" validate={required()} />
        <NumberInput source="stock" validate={required()} />
        <NumberInput source="cost_price" validate={required()} />
        <NumberInput source="selling_price" validate={required()} />
        <SelectInput
          source="gst"
          choices={[
            { id: 0, name: "GST @ 0%" },
            { id: 5, name: "GST @ 5%" },
            { id: 12, name: "GST @ 12%" },
            { id: 18, name: "GST @ 18%" },
          ]}
        />
        <TextInput source="hsn" validate={required()} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <span>
              <br />
              SP + GST : Rs{" "}
              <b>
                {Math.round(
                  (formData.selling_price || 0) *
                    (1 + (formData.gst || 0) / 100)
                )}
              </b>
            </span>
          )}
        </FormDataConsumer>
        <NumberInput source="mrp" validate={required()} />
        <BooleanInput source="is_enabled" />
        <BooleanInput source="on_website" label="Show on Website" />
        <BooleanInput source="show_stock" label="Show Stock on Website" />
      </FormTab>
      <FormTab label="Image">
        <ImageInput
          source="image_1"
          label="Image_1"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          source="image_2"
          label="Image_2"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          source="image_3"
          label="Image_3"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          source="image_4"
          label="Image_4"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          source="image_5"
          label="Image_5"
          accept="image/*"
          multiple={false}
          placeholder={<p>Drop your file here</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Description">
        <RichTextInput
          source="detailed_description"
          title="Detailed Description"
        />
        <CustomReferenceArrayInput
          source="tags"
          reference="tags"
          label="Tags"
          perPage={9999}
          validate={required()}
          field="id"
        />
      </FormTab>
      <FormTab label="Size">
        <NumberInput source="width" label="Width (in)" />
        <NumberInput source="height" label="Height (in)" />
        <NumberInput source="weight" label="Weight (gm)" />
        <br />
        <span>OR</span>
        <TextInput source="size" label="Any Other Size" />
      </FormTab>
    </TabbedForm>
  </Create>
);
