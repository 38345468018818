import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  Filter,
  SearchInput,
  FunctionField,
  ReferenceField,
  BulkDeleteButton,
} from "react-admin";
import CopyButton from "./copyButton";
import Icon from "@material-ui/icons/FiberManualRecord";

const ProductsFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const ActionButtons = (props) => (
  <Fragment>
    <CopyButton {...props} />
    <BulkDeleteButton {...props} undoable={false} />
  </Fragment>
);

export default (props) => (
  <List
    {...props}
    filters={<ProductsFilter />}
    bulkActionButtons={<ActionButtons history={props.history} />}
  >
    <Datagrid>
      <FunctionField
        label="Image"
        render={(record) =>
          record.image_1 && (
            <img
              src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${record.image_1}`}
              alt={record.supc}
            />
          )
        }
      />
      <ReferenceField
        label="Category"
        source="category_id"
        reference="categories"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Sub-Category"
        source="sub_category_id"
        reference="sub_categories"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" />
      <FunctionField
        label="Price(with Gst)"
        render={(record) =>
          `₹${Math.round(record.selling_price * (1 + record.gst / 100))}`
        }
      />
      <NumberField source="stock" />

      <FunctionField
        label="Live"
        render={(record) =>
          record.is_enabled ? (
            <Icon style={{ color: "green" }} />
          ) : (
            <Icon style={{ color: "red" }} />
          )
        }
      />
      <FunctionField
        label="On Website"
        render={(record) =>
          record.on_website ? (
            <Icon style={{ color: "blue" }} />
          ) : (
            <Icon style={{ color: "red" }} />
          )
        }
      />
      <EditButton />
    </Datagrid>
  </List>
);
