import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { apiUrl } from "./constants";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({});
  }
  const token = localStorage.getItem("admin_token");
  options.headers.set("Accept", "application/json");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      sort: field,
      order,
      perPage,
      page,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json: { data, meta, links } }) => {
      if (resource === "orders") {
        const ord = new URLSearchParams(
          links.first.substring(links.first.indexOf("?") + 1)
        );
        localStorage.setItem(
          `${resource}_count`,
          JSON.stringify(Object.fromEntries(ord))
        );
      }
      return {
        data,
        total: meta.total,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(
      `${apiUrl}/${resource}/${params.id}`
    ).then(({ json: { data } }) => ({ data })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json: { data } }) => ({ data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      orderBy: field,
      order,
      limit: perPage,
      startAfter: page,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json: { data, meta } }) => ({
      data,
      total: meta.total,
    }));
  },

  update: async (resource, params) => {
    let props = params;
    if (resources === resource) {
      props = await imageProps(params);
    }
    if (resourcesProducts.includes(resource)) {
      props = await productProps(params);
    }
    console.log(props);
    return httpClient(`${apiUrl}/${resource}/${props.id}`, {
      method: "PUT",
      body: JSON.stringify(props.data),
    }).then(({ json: { data } }) => ({ data }));
  },

  updateMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  },

  create: async (resource, params) => {
    let props = params;
    if (resources === resource) {
      props = await imageProps(params);
    }
    if (resourcesProducts.includes(resource)) {
      props = await productProps(params);
    }
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(props.data),
    }).then(({ json: { data } }) => ({
      data: { ...props.data, id: data.id },
    }));
  },

  createMany: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/bulk-upload`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json: { data } }) => ({ data })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json: { data } }) => ({ data })),

  deleteMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          body: JSON.stringify(params.data),
        })
      )
    ).then(() => ({ data: "" }));
  },

  deleteImage: (resource, params) => {
    return httpClient(
      `${apiUrl}/${resource}/delete-image?${stringify(params)}`
    ).then(() => ({ data: "" }));
  },
  pdfDownload: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(
      `${apiUrl}/${resource}/${params.name}?${stringify(query)}`
    ).then((response) => {
      const linkSource = `data:application/pdf;base64,${response.body}`;
      const downloadLink = document.createElement("a");
      const fileName = `${params.name}-${Date.now()}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      return { data: [] };
    });
  },
  sms: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(
      `${apiUrl}/${resource}/${params.name}?${stringify(query)}`
    ).then(() => {
      return { data: [] };
    });
  },
};

const resources = "advertisements";
const resourcesProducts = ["products", "product_variants"];

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const productProps = async (params) => {
  const { image_1, image_2, image_3, image_4, image_5 } = params.data;
  return {
    ...params,
    data: {
      ...params.data,
      image_1:
        image_1 instanceof Object
          ? {
              src: await convertFileToBase64(image_1),
              title: image_1.title,
            }
          : image_1,
      image_2:
        image_2 instanceof Object
          ? {
              src: await convertFileToBase64(image_2),
              title: image_2.title,
            }
          : image_2,
      image_3:
        image_3 instanceof Object
          ? {
              src: await convertFileToBase64(image_3),
              title: image_3.title,
            }
          : image_3,
      image_4:
        image_4 instanceof Object
          ? {
              src: await convertFileToBase64(image_4),
              title: image_4.title,
            }
          : image_4,
      image_5:
        image_5 instanceof Object
          ? {
              src: await convertFileToBase64(image_5),
              title: image_5.title,
            }
          : image_5,
    },
  };
};

const imageProps = async (params) => {
  const { image } = params.data;
  return {
    ...params,
    data: {
      ...params.data,
      image:
        image instanceof Object
          ? {
              src: await convertFileToBase64(image),
              title: image.title,
            }
          : image,
    },
  };
};
