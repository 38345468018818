import React from "react";
import {
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
} from "react-admin";
import DeleteButton from "../../components/deleteButton";

export default (props) => (
  <Datagrid {...props}>
    <ReferenceField source="product_id" reference="products">
      <TextField source="title" />
    </ReferenceField>
    <NumberField source="price" />
    <NumberField source="quantity" />
    <NumberField source="total" />
    <FunctionField
      label=" "
      render={(record) => <DeleteButton resource="sub_orders" id={record.id} />}
    />
  </Datagrid>
);
