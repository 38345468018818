import React, { useState, useCallback } from "react";
import {
  required,
  Button,
  SaveButton,
  TextInput,
  NumberInput,
  SelectInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function ProductQuickCreateButton({ onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("products");
  const notify = useNotify();
  const classes = useStyles();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const postDefaultValue = { is_enabled: true };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create user"
      >
        <DialogTitle>Create Product</DialogTitle>

        <FormWithRedirect
          resource="products"
          save={handleSubmit}
          initialValues={postDefaultValue}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ReferenceInput
                  source="category_id"
                  reference="categories"
                  validate={required()}
                  className={classes.input}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    formData.category_id && (
                      <ReferenceInput
                        source="sub_category_id"
                        reference="sub_categories"
                        resource="sub_categories"
                        filter={{ categories_id: formData.category_id }}
                        validate={required()}
                        className={classes.input}
                      >
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
                <TextInput
                  source="title"
                  validate={required()}
                  className={classes.fullInput}
                />

                <TextInput
                  source="supc"
                  validate={required()}
                  className={classes.input}
                />
                <NumberInput
                  source="stock"
                  validate={required()}
                  className={classes.input}
                />
                <NumberInput
                  source="cost_price"
                  validate={required()}
                  className={classes.input}
                />
                <NumberInput
                  source="selling_price"
                  validate={required()}
                  className={classes.input}
                />
                <NumberInput
                  source="mrp"
                  validate={required()}
                  className={classes.input}
                />
                <SelectInput
                  source="gst"
                  choices={[
                    { id: 0, name: "GST @ 0%" },
                    { id: 5, name: "GST @ 5%" },
                    { id: 18, name: "GST @ 18%" },
                  ]}
                  validate={required()}
                  className={classes.input}
                />
                <TextInput source="hsn" className={classes.fullInput} />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginRight: theme.spacing(2),
    width: "260px",
  },
  fullInput: {
    width: "532px",
  },
}));

const ProductReferenceInput = (props) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);
  return (
    <div className={classes.root}>
      <ReferenceInput key={version} {...props}>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <ProductQuickCreateButton onChange={handleChange} />
    </div>
  );
};

export default ProductReferenceInput;
