import React from "react";
import {
  Edit,
  NumberInput,
  TextInput,
  ImageInput,
  BooleanInput,
  ImageField,
  TabbedForm,
  FormTab,
  SelectInput,
  required,
  FormDataConsumer,
  ReferenceManyField,
  FunctionField,
  TopToolbar,
} from "react-admin";
import RichTextInput from "../../components/richInputText";
import { withStyles } from "@material-ui/core";
import VariantButton from "../productVariant/createButton";
import FieldButton from "../productField/createButton";
import VariantList from "../productVariant/list";
import FieldList from "../productField/list";
import DeleteButton from "../../components/deleteImageButton";
import CustomReferenceInput from "../../components/createButton";
import CustomReferenceArrayInput from "../tag/createButton";
import SubCatReferenceInput from "../subCategory/createButton";

const styles = {
  fullWidth: { width: "100%" },
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
};

const ProductEditActions = (props) => (
  <TopToolbar>
    <VariantButton {...props} />
    <FieldButton {...props} />
  </TopToolbar>
);

export default withStyles(styles)(({ classes, history, ...props }) => (
  <Edit
    {...props}
    undoable={false}
    actions={<ProductEditActions history={history} />}
  >
    <TabbedForm redirect={false}>
      <FormTab label="Details">
        <CustomReferenceInput
          source="category_id"
          reference="categories"
          perPage={9999}
          validate={required()}
          field="name"
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.category_id && (
              <SubCatReferenceInput
                source="sub_category_id"
                reference="sub_categories"
                resource="sub_categories"
                perPage={9999}
                filter={{ categories_id: formData.category_id }}
                validate={required()}
                field="name"
              />
            )
          }
        </FormDataConsumer>

        <TextInput source="title" validate={required()} />
        <TextInput
          source="supc"
          label="SUPC (eg. NAMEPLATE01)"
          formClassName={classes.inlineBlock}
          validate={required()}
        />
        <NumberInput
          source="stock"
          formClassName={classes.inlineBlock}
          validate={required()}
        />
        <NumberInput
          source="cost_price"
          label="Cost Price(Rs)"
          formClassName={classes.inlineBlock}
          validate={required()}
        />
        <NumberInput
          source="selling_price"
          label="Selling Price(Rs)"
          validate={required()}
        />
        <SelectInput
          source="gst"
          choices={[
            { id: 0, name: "GST @ 0%" },
            { id: 5, name: "GST @ 5%" },
            { id: 12, name: "GST @ 12%" },
            { id: 18, name: "GST @ 18%" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <span>
              <br />
              SP + GST : Rs{" "}
              <b>
                {Math.round(
                  (formData.selling_price || 0) *
                    (1 + (formData.gst || 0) / 100)
                )}
              </b>
            </span>
          )}
        </FormDataConsumer>
        <NumberInput
          source="mrp"
          label="MRP(Rs)"
          formClassName={classes.inlineBlock}
          validate={required()}
        />
        <TextInput source="hsn" validate={required()} />
        <BooleanInput source="is_enabled" label="Activate" />
        <BooleanInput source="on_website" label="Show on Website" />
        <BooleanInput source="show_stock" label="Show Stock on Website" />
      </FormTab>
      <FormTab label="Image">
        {[1, 2, 3, 4, 5].map((i) => [
          <ImageInput
            source={`image_${i}`}
            label={`image ${i}`}
            accept="image/*"
            multiple={false}
            placeholder={<p>Drop your file here</p>}
          >
            <ImageField source="src" title="title" />
          </ImageInput>,
          <FunctionField
            source=" "
            render={(record) =>
              record[`image_${i}`] && (
                <React.Fragment>
                  <img
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${
                      record[`image_${i}`]
                    }`}
                    alt={record[`image_${i}`]}
                    style={{ position: "relative" }}
                  />
                  <DeleteButton
                    resource="products"
                    id={record.id}
                    image={`image_${i}`}
                    image_id={record[`image_${i}`]}
                  />
                </React.Fragment>
              )
            }
          />,
        ])}
      </FormTab>
      <FormTab label="Description">
        <RichTextInput
          source="detailed_description"
          title="Detailed Description"
        />
        <CustomReferenceArrayInput
          source="tags"
          reference="tags"
          label="Tags"
          perPage={9999}
          validate={required()}
          field="id"
        />
      </FormTab>
      <FormTab label="Size">
        <NumberInput source="width" label="Width (in)" />
        <NumberInput source="height" label="Height (in)" />
        <NumberInput source="weight" label="Weight (gm)" />
        <FunctionField
          source=" "
          render={() => (
            <span>
              <b>OR</b>
            </span>
          )}
        />
        <TextInput source="size" label="Any Other Size" />
      </FormTab>
      <FormTab label="Fields">
        <ReferenceManyField
          className={classes.fullWidth}
          label=" "
          reference="product_fields"
          target="product_id"
          sort={{ field: "priority", order: "ASC" }}
        >
          <FieldList />
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Variants">
        <ReferenceManyField
          className={classes.fullWidth}
          label=" "
          reference="product_variants"
          target="variant_id"
        >
          <VariantList />
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
));
