import React from "react";
import {
  Edit,
  SimpleForm,
  NumberInput,
  TextInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  required,
  Button,
} from "react-admin";
import { withStyles } from "@material-ui/core";

const styles = {
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
  inlineImage: { display: "block", marginRight: "1rem" },
};

const redirect = (basePath, id, data) => {
  return `/products/${data.variant_id}/5`;
};

export default withStyles(styles)(({ classes, history, ...props }) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Button label="Back" onClick={() => history.goBack()} />}
  >
    <SimpleForm redirect={redirect}>
      <TextInput
        source="supc"
        label="SUPC (eg NAMEPLATE01)"
        formClassName={classes.inlineBlock}
        validate={required()}
      />
      <SelectInput
        formClassName={classes.inlineBlock}
        source="type"
        choices={[
          // { id: "COLOR", name: "COLOR" },
          { id: "SIZE", name: "SIZE" },
        ]}
      />
      <NumberInput
        source="stock"
        formClassName={classes.inlineBlock}
        validate={required()}
      />
      <br />
      <NumberInput
        source="cost_price"
        label="Cost Price(Rs)"
        formClassName={classes.inlineBlock}
        validate={required()}
      />
      <NumberInput
        source="selling_price"
        label="Selling Price (Rs)"
        formClassName={classes.inlineBlock}
        validate={required()}
      />

      <NumberInput
        source="gst"
        label="GST (%)"
        formClassName={classes.inlineBlock}
        validate={required()}
      />
      <NumberInput
        source="mrp"
        label="MRP (Rs)"
        formClassName={classes.inlineBlock}
        validate={required()}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === "SIZE" && [
            <NumberInput source="width" label="Width (in)" />,
            <br />,
            <NumberInput source="height" label="Height (in)" />,
            <br />,
            <NumberInput source="weight" label="Weight (gm)" />,
            <br />,
            <br />,
            <span>
              <b>OR</b>
            </span>,
            <br />,
            <TextInput source="size" label="Any Other Size" />,
          ]
        }
      </FormDataConsumer>

      <BooleanInput source="is_enabled" label="Activate" />
    </SimpleForm>
  </Edit>
));
