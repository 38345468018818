import * as React from "react";
import { useState } from "react";
import { Confirm, useRefresh, useNotify, useDataProvider } from "react-admin";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/RemoveCircleOutline";

const DeleteButton = ({ resource, id, image_id, image }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    dataProvider
      .deleteImage(resource, { id, image, image_id })
      .then(() => {
        refresh();
        notify("Updated");
      })
      .catch((err) => {
        notify("Error: Not updated", "warning");
      });
    setOpen(false);
  };

  return (
    <IconButton style={{ position: "absolute" }} onClick={handleClick}>
      <DeleteIcon />
      <Confirm
        title="Delete Image"
        content="Are you sure you want to delete this Image?"
        isOpen={open}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </IconButton>
  );
};

export default DeleteButton;
