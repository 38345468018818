import React from "react";
import {
  Edit,
  SimpleForm,
  required,
  TextField,
  TextInput,
  ImageInput,
  ImageField,
  FunctionField,
  Toolbar,
  SaveButton,
} from "react-admin";
import DeleteButton from "../../components/deleteImageButton";

export default (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect={`/advertisements`} toolbar={<EditToolbar />}>
      <TextField source="name" label="Name" />
      <ImageInput
        source="image"
        label="image"
        accept="image/*"
        multiple={false}
        placeholder={<p>Drop your file here</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <FunctionField
        source=" "
        render={(record) =>
          record.image && (
            <React.Fragment>
              <img
                src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${record.image}`}
                alt={record.image}
                style={{ position: "relative" }}
              />
              <DeleteButton
                resource="advertisements"
                id={record.id}
                image="image"
                image_id={record.image}
              />
            </React.Fragment>
          )
        }
      />
      <TextInput source="link" label="Link" validate={required()} />
    </SimpleForm>
  </Edit>
);

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton undoable={false} />
  </Toolbar>
);
