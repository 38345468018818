import React from "react";
import {
  NumberInput,
  Edit,
  SelectInput,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  DateInput,
  required,
  FormDataConsumer,
  Button,
  TopToolbar,
  ShowButton,
} from "react-admin";
import UserReferenceInput from "../user/createButton";
import { makeStyles } from "@material-ui/core/styles";
import SubOrderList from "../subOrder/list";
import ConvertToInvoice from "../../components/convertToInvoice";

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: "100%" },
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
  block: { display: "block" },
  textField: {
    width: "256px",
    color: "#000",
  },
}));

const PostEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
    {data && data.order_type === "ESTIMATE" && (
      <ConvertToInvoice record={data} resource={resource} />
    )}
  </TopToolbar>
);

function OrderEdit({ history, ...props }) {
  const classes = useStyles();
  return (
    <Edit {...props} actions={<PostEditActions />}>
      <TabbedForm>
        <FormTab label="Details">
          <ReferenceManyField
            className={classes.fullWidth}
            label="Items"
            reference="sub_orders"
            target="order_id"
          >
            <SubOrderList />
          </ReferenceManyField>
          <FormDataConsumer>
            {({ formData }) => (
              <Button
                label="Add Item"
                onClick={() =>
                  history.push(`/sub_orders/create?order_id=${formData.id}`)
                }
              />
            )}
          </FormDataConsumer>
          <UserReferenceInput
            source="user_id"
            reference="users"
            filterToQuery={(searchText) => ({ phone: searchText })}
            perPage={9999}
            sort={{ field: "phone", order: "ASC" }}
          />
          <TextInput source="billing_name" validate={required()} />
          <TextInput disabled source="sub_total" label="SubTotal (Rs)" />
          <NumberInput source="discount" label="Discount (Rs)" />
          <NumberInput source="shipping" label="Shipping (Rs)" />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <TextInput
                disabled
                label="Total (Rs)"
                value={
                  formData.sub_total - formData.discount + formData.shipping ||
                  0
                }
                className={classes.textField}
              />
            )}
          </FormDataConsumer>
          <NumberInput source="paid" label="Paid (Rs)" />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <TextInput
                disabled
                label="Balance Due (Rs)"
                value={
                  formData.sub_total -
                  formData.discount +
                  formData.shipping -
                  formData.paid
                }
                className={classes.textField}
              />
            )}
          </FormDataConsumer>
          <SelectInput
            source="order_status"
            choices={[
              { id: "PREPARING", name: "PREPARING" },
              { id: "ON_HOLD", name: "ON_HOLD" },
              { id: "READY_FOR_DISPATCH", name: "READY_FOR_DISPATCH" },
              { id: "DISPATCHED", name: "DISPATCHED" },
              { id: "DELIVERED", name: "DELIVERED" },
            ]}
            validate={required()}
          />
          <TextInput source="po" label="Payment Order" />
          <DateInput source="delivery_date" label="Delivery Date" />
        </FormTab>
        <FormTab label="Billing">
          <TextInput source="billing_address" />
          <TextInput source="billing_city" />
          <TextInput source="billing_state" />
          <NumberInput source="billing_zipcode" />
          <TextInput source="billing_country" />
          <TextInput source="billing_tel" />
        </FormTab>
        <FormTab label="Shipping">
          <TextInput source="shipping_name" />
          <TextInput source="shipping_address" />
          <TextInput source="shipping_city" />
          <TextInput source="shipping_state" />
          <NumberInput source="shipping_zipcode" />
          <TextInput source="shipping_country" />
          <TextInput source="shipping_tel" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

export default OrderEdit;
