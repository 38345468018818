import * as React from "react";

import { Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";

import { useNotify, useDataProvider, useRefresh } from "react-admin";

export default ({ resource, id }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  function handleDelete() {
    dataProvider
      .delete(resource, { id })
      .then(() => {
        refresh();
        notify("Element Deleted");
      })
      .catch(() => {
        notify("Error: Variant not created", "warning");
      });
  }
  return (
    <Button style={{ color: "red" }} onClick={handleDelete} size="small">
      <Delete />
    </Button>
  );
};
