import List from "./list";
import Edit from "./edit";
import Icon from "@material-ui/icons/Dashboard";

export default {
  list: List,
  edit: Edit,
  icon: Icon,
  label: "Dashboard",
  options: { label: "Dashboard" },
  name: "dashboards",
};
