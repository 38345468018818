import React from "react";
import {
  FormDataConsumer,
  Edit,
  NumberInput,
  BooleanInput,
  TextInput,
  SimpleForm,
  required,
} from "react-admin";
import PhoneInput from "../../components/phoneInput";

export default (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source='name' />
      <TextInput source='email' />
      <TextInput source='address' />
      <PhoneInput source='phone' title='Phone' />
      <TextInput source='city' />
      <TextInput source='state' />
      <TextInput source='country' />
      <TextInput source='referral_id' label='Referral Code' />
      <NumberInput source='zipcode' />
      <BooleanInput source='is_active' label='Active' />
      <BooleanInput source='is_vendor' label='is Vendor' />
      <BooleanInput source='is_reseller' label='is Reseller' />

      <FormDataConsumer>
        {({ formData }) =>
          formData.is_reseller || formData.is_vendor ? (
            <NumberInput
              source='discount'
              label='Commission'
              validate={required()}
            />
          ) : (
            ""
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
