import * as React from "react";
import { Fragment, useState } from "react";
import {
  Button,
  Confirm,
  useUpdate,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";

const ConvertToInvoice = ({ resource, record }) => {
  const { id } = record;
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading }] = useUpdate(
    resource,
    id,
    { order_type: "INVOICE" },
    record,
    {
      onSuccess: () => {
        refresh();
        notify("Updated");
      },
      onFailure: () => notify("Error: Not updated", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    update();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button label="Convert-To-Invoice" onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Update Order"
        content="Are you sure you want to update the order(s)?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default ConvertToInvoice;
