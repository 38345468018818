import List from './list';
import Edit from './edit';
import Create from './create';
import Icon from '@material-ui/icons/FontDownload';

export default {
	list: List,
	edit: Edit,
	create: Create,
	icon: Icon,
	label: 'Fonts',
	options: { label: 'Fonts' },
	name: 'fonts',
};
