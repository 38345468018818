import React from "react";
import ReactQuill from "react-quill";
import { Labeled, useInput } from "react-admin";
import "react-quill/dist/quill.snow.css";

export default function RichInput(props) {
  const { input } = useInput(props);
  return (
    <Labeled label={props.title}>
      <ReactQuill {...input} />
    </Labeled>
  );
}
