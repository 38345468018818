import React from "react";
import {
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  NumberField,
} from "react-admin";
import Icon from "@material-ui/icons/FiberManualRecord";
import DeleteButton from "../../components/deleteButton";

export default (props) => (
  <Datagrid {...props}>
    <EditButton />
    <TextField source="supc" />
    <TextField source="type" />
    <NumberField
      source="mrp"
      options={{ style: "currency", currency: "INR" }}
    />
    <NumberField source="stock" />
    <TextField source="size" />
    <FunctionField
      label="Live"
      render={(record) =>
        record.is_enabled ? (
          <Icon style={{ color: "green" }} />
        ) : (
          <Icon style={{ color: "red" }} />
        )
      }
    />
    <FunctionField
      label=" "
      render={(record) => (
        <DeleteButton resource="product_variants" id={record.id} />
      )}
    />
  </Datagrid>
);
