import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";

import dataProvider from "./dataProvider";
import authProvider from "./authProvider";

import category from "./containers/category";
import subCategory from "./containers/subCategory/index";
import advertisement from "./containers/advertisement/index";
import admin from "./containers/admin/index";
import color from "./containers/color/index";
import dashboard from "./containers/dashboard/index";
import font from "./containers/font/index";
import tag from "./containers/tag/index";
import product from "./containers/product/index";
import productVariant from "./containers/productVariant";
import productField from "./containers/productField";
import order from "./containers/order";
import subOrder from "./containers/subOrder";

import user from "./containers/user/index";

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
  >
    {(permissions) => [
      permissions.includes("orders") && <Resource {...order} />,
      <Resource {...subOrder} />,
      permissions.includes("categories") && <Resource {...category} />,
      permissions.includes("sub_categories") && <Resource {...subCategory} />,
      permissions.includes("products") && <Resource {...product} />,
      <Resource {...productVariant} />,
      <Resource {...font} />,
      <Resource {...color} />,
      <Resource {...tag} />,
      <Resource {...dashboard} />,
      <Resource {...productField} />,
      permissions.includes("users") && <Resource {...user} />,
      permissions.includes("admins") && <Resource {...admin} />,
      permissions.includes("advertisements") && <Resource {...advertisement} />,
    ]}
  </Admin>
);

export default App;
