import React, { useState, useCallback } from "react";

import {
  required,
  Button,
  SaveButton,
  NumberInput,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  BooleanInput,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PhoneInput from "../../components/phoneInput";

function UserQuickCreateButton({ onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("users");
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };
  const postDefaultValue = { is_active: true };

  return (
    <>
      <Button onClick={handleClick} label="User">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create user"
      >
        <DialogTitle>Create user</DialogTitle>

        <FormWithRedirect
          resource="users"
          save={handleSubmit}
          initialValues={postDefaultValue}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source="name" validate={required()} fullWidth />
                <PhoneInput source="phone" title="Phone" />
                <TextInput source="gst" label="GSTIN (optional)" fullWidth />
                <BooleanInput source="is_reseller" label="Is Reseller ?" />
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.is_reseller && (
                      <NumberInput
                        source="discount"
                        validate={required()}
                        fullWidth
                      />
                    )
                  }
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

const useStyles = makeStyles({
  referenceInput: {
    width: "256px",
  },
});

const UserReferenceInput = (props) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);
  return (
    <div className={classes.root}>
      <ReferenceInput {...props} className={classes.referenceInput}>
        <AutocompleteInput
          optionText="phone"
          // className={classes.referenceInput}
          formClassName={classes.referenceInput}
        />
      </ReferenceInput>
      <UserQuickCreateButton onChange={handleChange} />
    </div>
  );
};

export default UserReferenceInput;
