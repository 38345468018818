import React from 'react';
import { Create, SimpleForm, required, TextInput } from 'react-admin';

export default props => (
	<Create {...props} undoable={false}>
		<SimpleForm redirect={`/fonts`}>
			<TextInput source="id" label="name" validate={required()} />
		</SimpleForm>
	</Create>
);
