import React, { useState, useCallback } from "react";
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  ReferenceArrayInput,
  SelectArrayInput,
  useRefresh,
} from "react-admin";

import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function CustomQuickCreateButton({ onChange, name, field, source }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate(name);

  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create"
      >
        <DialogTitle>Create</DialogTitle>

        <FormWithRedirect
          resource={name}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source={field} validate={required()} fullWidth />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    width: "256px",
  },
});

const CustomReferenceInput = (props) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div className={classes.root}>
      <ReferenceArrayInput key={version} {...props} fullWidth>
        <SelectArrayInput optionText={props.field} />
      </ReferenceArrayInput>
      <CustomQuickCreateButton
        onChange={handleChange}
        name={props.reference}
        source={props.source}
        field={props.field}
      />
    </div>
  );
};

export default CustomReferenceInput;
