import React from "react";
import { Create, NumberInput, SimpleForm, required } from "react-admin";
import ProductReferenceInput from "../product/createButton";

export default (props) => {
  const o = new URLSearchParams(
    window.location.hash.split("#")[1].split("?")[1]
  );
  const order_id = o.get("order_id");
  const postDefaultValue = { order_id };

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={postDefaultValue}
        redirect={`/orders/${order_id}`}
      >
        <ProductReferenceInput
          label="Product"
          source="product_id"
          reference="products"
          validate={required()}
          perPage={9999}
          sort={{ field: "title", order: "ASC" }}
          fullWidth
          filterToQuery={(searchText) => ({ title: searchText })}
        />

        <NumberInput source="quantity" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
