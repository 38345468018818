import List from './list';
import Edit from './edit';
import Create from './create';
import Icon from '@material-ui/icons/Person';

export default {
	list: List,
	edit: Edit,
	create: Create,
	icon: Icon,
	label: 'Users',
	options: { label: 'Users' },
	name: 'users',
};
