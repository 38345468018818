import React from "react";
import { Button } from "react-admin";
import { useNotify, useDataProvider } from "react-admin";

function SMSButton({ selectedIds, resource, name, label }) {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  function handleClick() {
    dataProvider
      .sms(resource, { ids: selectedIds, name })
      .then(() => {
        notify(`${name} generated`);
      })
      .catch((err) => {
        console.log(err);
        notify(`Error: ${label} not created`, "warning");
      });
  }

  return <Button label={label} onClick={handleClick} />;
}

export default SMSButton;
