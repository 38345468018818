import React from "react";
import {
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
} from "react-admin";
import DeleteButton from "../../components/deleteButton";

export default (props) => (
  <Datagrid {...props}>
    <EditButton />
    <TextField source="type" />
    <TextField source="title" />
    <TextField source="sub_text" />
    <NumberField source="max_count" />
    <ReferenceArrayField label="Fonts" reference="fonts" source="font">
      <SingleFieldList>
        <ChipField source="id" />
      </SingleFieldList>
    </ReferenceArrayField>
    <ReferenceArrayField label="Colors" reference="colors" source="color">
      <SingleFieldList>
        <ChipField source="id" />
      </SingleFieldList>
    </ReferenceArrayField>
    <NumberField source="priority" />
    <FunctionField
      label=" "
      render={(record) => (
        <DeleteButton resource="product_fields" id={record.id} />
      )}
    />
  </Datagrid>
);
