import React from "react";
import {
  TextField,
  Create,
  NumberInput,
  TextInput,
  SimpleForm,
  BooleanInput,
  FormDataConsumer,
  required,
} from "react-admin";
import PhoneInput from "../../components/phoneInput";

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='name' />
      <TextInput source='email' />
      <TextInput source='address' />
      <PhoneInput source='phone' title='Phone' />
      <TextInput source='city' />
      <TextInput source='state' />
      <TextInput source='country' />
      <TextInput source='referral_id' label='Referral Code' />
      <NumberInput source='zipcode' />
      <BooleanInput source='is_active' label='Active' />
      <BooleanInput source='is_vendor' label='is Vendor' />
      <BooleanInput source='is_reseller' label='is Reseller' />
      <FormDataConsumer>
        {({ formData }) =>
          formData.is_reseller ? (
            <NumberInput source='discount' validate={required()} />
          ) : (
            ""
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);
