import React from "react";
import { useNotify, useDataProvider, Button } from "react-admin";

function CreateButton(props) {
  const { withGst, orderType, label, history } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  function handleClick() {
    dataProvider
      .create("orders", { data: { with_gst: withGst, order_type: orderType } })
      .then((res) => {
        history.push(`/orders/${res.data.id}`);
        notify("Order Created");
      })
      .catch((err) => {
        console.log(err);
        notify("Error: Order not created", "warning");
      });
  }

  return <Button label={label} onClick={handleClick} />;
}

export default CreateButton;
