import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  SearchInput,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  SelectInput,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <SearchInput source='q' alwaysOn />
    <ReferenceInput source='user_id' reference='users'>
      <AutocompleteInput optionText={(choice) => choice.name} />
    </ReferenceInput>
    <SelectInput
      source='is_active'
      choices={[
        { id: "0", name: "No" },
        { id: "1", name: "Yes" },
      ]}
    />
  </Filter>
);

export default (props) => (
  <List {...props} filters={<Filters />}>
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='name' />
      <TextField source='phone' />
      <TextField source='referral_id' label='Referral Code' />
      <BooleanField source='is_active' />
      <BooleanField source='is_vendor' />
      65
    </Datagrid>
  </List>
);
