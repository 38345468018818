import React from "react";
import { Labeled, useInput } from "react-admin";
import MuiPhoneNumber from "material-ui-phone-number";

export default function PhoneInput(props) {
  const { input } = useInput(props);

  return (
    <Labeled label={props.title}>
      <MuiPhoneNumber defaultCountry={"in"} {...input} autoFormat={false} />
    </Labeled>
  );
}
