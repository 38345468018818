export default [
  { name: "Orders", id: "orders" },
  { name: "Sub-Orders", id: "sub_orders" },
  { name: "Users", id: "users" },
  { name: "User Transactions", id: "user_transactions" },
  { name: "Advertisements", id: "advertisements" },
  { name: "Categories", id: "categories" },
  { name: "Sub-Categories", id: "sub_categories" },
  { name: "Products", id: "products" },
  { name: "Products List", id: "products_list" },
  { name: "Blogs", id: "blogs" },
  { name: "Settings", id: "settings" },
  { name: "Wallet Requests", id: "wallet_requests" },
  { name: "Admin", id: "admins" },
];
